import {ChangeDetectionStrategy, Component, input } from '@angular/core';
import {ControlContainer, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';

@Component({
    selector: 's-textarea',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [ReactiveFormsModule],
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
    template: `
    <textarea class="form-control" [formControlName]="controlName()" [rows]="rows()" style="resize: none" [placeholder]="placeholder()" ></textarea>
  `,
    styles: `
    .form-control, .form-control:focus{
      box-shadow: none;
      border-color: var(--gray);
      outline: 0;
      border: 1px solid var(--gray);
    }
  `
})
export class TextareaComponent {
  placeholder = input<string>("");
  rows = input<number>(5);
  controlName = input.required<string>();
  
}
