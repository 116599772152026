import { NgClass } from '@angular/common';
import { Component, input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 's-button',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgClass],
    template: `
   <button (click)="onClick()" [disabled]="disable()" [style.background-color]="bgColor()">
     @if(icon()){
       <img [src]="icon()" class="me-2" style="height: auto; width: 15px;" />
     }
     {{label()}}
   </button>
  `,
    styles: `
    button,button.btn-rounded{
      height: 100%;
      border: 0;
      color: white;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      transition: var(--transitions);
    }
    button{
      padding: 8px 12px;
      border-radius: 5px;
    }
    button.btn-rounded {
    padding: 4px 20px;
    border-radius: 16px;
    transition: unset;
  }
    button:hover{
      filter: brightness(1.1);
    }
    button:disabled{
      pointer-events: none;
      opacity: .4;
    }
  `
})
export class ButtonComponent {
  label = input.required<string>();
  bgColor = input<"var(--main)" | "var(--green)">("var(--main)");
  icon = input<string>();
  disable = input<boolean>(false);
  @Output() clicked = new EventEmitter();

  onClick(){
    this.clicked.emit()
  }
}
