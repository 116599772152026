import {ApplicationConfig, importProvidersFrom, inject, PLATFORM_ID} from '@angular/core';
import {InMemoryScrollingOptions, provideRouter, withInMemoryScrolling, withViewTransitions} from '@angular/router';
import { routes, underConstructionRoute } from './app.routes';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import {tokenInterceptor} from "./helpers/tokenInterceptor";
import {errorInterceptor} from "./helpers/error.interceptor";
import {environment} from "../environments/environment";
import {NgxPiwikProModule} from "@piwikpro/ngx-piwik-pro";
import { provideClientHydration, withEventReplay, withHttpTransferCacheOptions } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';

const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'top'
};

if(environment.underCostruction){
  var route = underConstructionRoute;
} else {
  var route = routes;
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(route, withViewTransitions(), withInMemoryScrolling(scrollConfig)),
    provideHttpClient(
      withInterceptors([tokenInterceptor, errorInterceptor])
    ),
    provideClientHydration(withEventReplay(), 
    withHttpTransferCacheOptions({includePostRequests: true}))
  ]
};
