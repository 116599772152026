import {ChangeDetectionStrategy, Component} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {AlertComponent} from "./components/shared/alert/alert.component";
import { PiwikService } from './services/piwik.service';

@Component({
    selector: 'app-root',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [RouterOutlet, AlertComponent],
    template: `
    <router-outlet />
    <s-alert />
  `,
    providers: [PiwikService]
})
export class AppComponent {
  constructor(private piwikService: PiwikService) {}
}
