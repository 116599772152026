import { Injectable, Inject, PLATFORM_ID, inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import {
  HttpInterceptorFn,
  HttpRequest,
  HttpHandlerFn,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';

export function tokenInterceptor(req: HttpRequest<unknown>,next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  let token: string | null = null;
  //Verifica se il codice è eseguito lato browser
  let platformId = inject(PLATFORM_ID);
  if (typeof window !== 'undefined' && isPlatformBrowser(platformId)) {
    token = localStorage.getItem('TOKEN');
  }

  let reqCloned = req.clone();
  if (token) {
    reqCloned = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${token}`),
    });
  }

  return next(reqCloned);
}