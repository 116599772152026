import {ChangeDetectionStrategy, Component, EventEmitter, input, Output} from '@angular/core';

export interface NoteAction{
  notaId: number,
  action: "edit" | "delete"
}

@Component({
    selector: 'a-view-note',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [],
    templateUrl: './view-note.component.html',
    styleUrl: './view-note.component.css'
})
export class ViewNoteComponent {
  nota = input.required<string>();
  notaId = input.required<number>();
  @Output() action = new EventEmitter<NoteAction>();

  onAction(_action: "edit" | "delete"){
    this.action.emit({notaId: this.notaId(), action: _action});
  }
}
