import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgxPiwikProModule} from '@piwikpro/ngx-piwik-pro';
import  PiwikPro  from '@piwikpro/tracking-base-library';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PiwikService {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformBrowser(this.platformId) && environment.production) {
      PiwikPro.initialize('ae2d5926-83e6-4404-8e33-31781175e637', 'https://diamond.piwik.pro');
    }
  }
}
