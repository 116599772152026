import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject, combineLatest, first, of, tap, share} from 'rxjs';
import {AlertParam} from "../models/ModalParam";
import {NoteAction} from "../components/admin/view-note/view-note.component";

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  isOpen = new BehaviorSubject<boolean>(false);
  result = new Subject<boolean>();
  resultData = new Subject<any>();
  type!: "modal" | "alert" | "addNote" | "viewNote";
  param!: AlertParam;
  notaId?: number;
  timeoutId?: NodeJS.Timeout;

  setOpen(open: boolean){
    this.isOpen.next(open);
    if(this.timeoutId){
      clearTimeout(this.timeoutId);
    }
  }
  setResult(result: boolean){
    this.result.next(result);
  }

  setResultData(result: any){
    this.resultData.next(result);
  }

  modal(param: AlertParam){
    this.setOpen(true)
    this.type = 'modal';
    this.param = param;
    return this.result.pipe(first());
  }

  addNote(param: AlertParam){
    this.setOpen(true)
    this.type = 'addNote';
    this.param = param;
    const success$ = this.result.pipe(first());
    const data$: Observable<string> = this.resultData.pipe(first());

    return combineLatest({succes: success$, data: data$}).pipe(first());
  }

  viewNote(param: AlertParam, id: number){
    this.setOpen(true)
    this.type = 'viewNote';
    this.param = param;
    this.notaId = id;
    const data$: Observable<NoteAction> = this.resultData.pipe(first());
    return data$;
  }

  alert(param: AlertParam){
    this.setOpen(true);
    this.timeoutId = setTimeout(() => {
      this.setOpen(false)
    }, 3500);

    this.type = 'alert';
    this.param = param;
    return this.result.pipe(first());
  }

}
